import {Injectable} from '@angular/core';
import {EMPTY, from, Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, switchMap} from 'rxjs/operators';
import {catchAndPropagateError} from '../utils/observable.utils';

@Injectable({
    providedIn: 'root',
})
export class DownloadService {
    constructor(private httpClient: HttpClient) {
    }

    downloadByUrl(APIUrl: any, postData?: any, id?: number, headers?: {[key: string]: any}): Observable<any> {
        return this.httpClient.request<any>(postData ? 'POST' : 'GET', id ? APIUrl.download(id) : APIUrl, {
            headers: new HttpHeaders({
                'Authorization': `JWT ${localStorage.getItem('jwt-token')}`,
                ...headers,
            }),
            body: postData,
            responseType: 'blob' as 'json',
            observe: 'response',
        }).pipe(
            switchMap(response => {
                if (response.status === 200) {
                    return from(
                        new Response(response.body).arrayBuffer().then(buffer => {
                            if (id) return buffer;
                            return {
                                binary: buffer,
                                cdp: response.headers.get('Content-Disposition'),
                                xjson: response.headers.get('X-json-data'),
                            };
                        })
                    );
                }

                return throwError(response);
            }),
        );
    }

    getObjectUrlByUrl(APIUrl: any, postData?: any, id?: number, headers?: {[key: string]: any}): Observable<string> {
        return this.downloadByUrl(APIUrl, postData, id, headers).pipe(
            map(res => {
                let mimeType: string;
                const ext = res.cdp.split('.')[1];
                if (ext === 'pdf') {
                    mimeType = 'application/pdf';
                } else if (ext === 'html') {
                    mimeType = 'text/html';
                } else {
                    mimeType = `image/${ext}`;
                }
                return URL.createObjectURL(new Blob([res?.binary ?? res], {type: mimeType}));
            }),
        );
    }

    getBASE64Image(APIUrl: any, id: number) {
        return Observable.create(obs => {
            this.downloadByUrl(APIUrl, null, id).pipe(
                catchAndPropagateError(err => {
                    obs.error(err);
                    return EMPTY;
                })
            ).subscribe(a => {
                const base64 = btoa([].reduce.call(new Uint8Array(a), (p, c) => p + String.fromCharCode(c), '')).toString();
                obs.next(base64);
                obs.complete();
            });
        });
    }

    downloadFileByUrl(APIUrl: any, postData?: any, id?: number, fileName?: string, extension = '', headers?: {[key: string]: any}): Observable<any> {
        const obs = new Subject<{xJsonData} | void>();
        this.downloadByUrl(APIUrl, postData, id, headers).pipe(
            catchAndPropagateError(err => {
                obs.error(err);
                return EMPTY;
            })
        ).subscribe(a => {
            const file = new Blob([a?.binary ?? a], {type: 'application/octet-stream'});
            fileName = a?.cdp ? (a.cdp && a.cdp.split('=').length > 1 ? a.cdp.split('=')[1] : 'Facility.xlsx') : fileName + extension;

            const navigator = window.navigator as any;
            if (navigator?.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(file, fileName);
            } else {
                const element = document.createElement('a');
                document.body.appendChild(element);
                element.style.display = 'none';
                const objUrl = URL.createObjectURL(file);
                element.href = objUrl;
                element.download = fileName;
                element.click();
                //we need setTimeout here because of Safari
                setTimeout(() => window.URL.revokeObjectURL(objUrl), 100);
                document.body.removeChild(element);
            }
            a?.xjson ? obs.next(a.xjson) : obs.next();
            obs.complete();
        });
        return obs;
    }

    downloadDataAsFile(data: any, fileName: string, mimeType = 'text/plain') {
        const blob = new Blob([data], {type: mimeType});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
